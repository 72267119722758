<template>
  <div
    class="app align-content-stretch d-flex flex-wrap"
    :class="{ 'sidebar-hidden': !$isMobile() && isSxAsideHidden }"
  >
    <SideBar></SideBar>
    <div class="app-container">
      <Header></Header>
      <div class="app-content">
        <div class="content-wrapper">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  removeModalBackdrops,
  removeTooltips,
  removeModalDependecyClassesAndStyles,
} from "@/core/helpers/dom";
export default {
  name: "Layout",
  data() {
    return {
      isSxAsideHidden: false,
    };
  },
  methods: {
    getCurrentUserInStore() {
      var currentUser = this.$store.getters.userInfo;
      if (currentUser) {
        //
      }
    },
  },
  created() {
    this.getCurrentUserInStore();

    // is aside minimize
    var sxAsideMinimize = Object.readCookie("SxAsideMinimize");
    if (
      !String.isNullOrWhiteSpace(sxAsideMinimize) &&
      sxAsideMinimize == "on"
    ) {
      this.isSxAsideHidden = true;
    }
  },
  watch: {
    $route() {
      removeModalBackdrops();
      removeTooltips();
      removeModalDependecyClassesAndStyles();
    },
  },
};
</script>
